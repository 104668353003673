"use client";
import React from "react";

import { AntdProvider } from "@/provider/AntdProvider";

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  return (
    <html lang="en">
      <body>
      <AntdProvider>
        <h2>Root Something went wrong!</h2>
        <button onClick={() => reset()}>Try again</button>
      </AntdProvider>
      </body>
    </html>
  )
}